import { images } from "../../assets/img";
import { ServiceType } from "../interfaces/therapists.type";
import { aboutArtur, certificatesArtur, servicesArtur } from "./artur";
import { aboutMarzena, certificatesMarzena, servicesMarzena } from "./marzena";

export interface TherapetistsData {
  name: string;
  title: string;
  path: string;
  image: string;
  certificates: string[];
  content: string;
  services: ServiceType[];
}

export const therapistsData: TherapetistsData[] = [
  {
    name: "Artur Kulig",
    title: "certyfikowany psychoterapeuta<br>i superwizor",
    path: "/terapeuci/artur-kulig",
    image: images.artur,
    certificates: certificatesArtur,
    content: aboutArtur,
    services: servicesArtur,
  },
  {
    name: "Marzena Kulig",
    title: "certyfikowany psychoterapeuta",
    path: "/terapeuci/marzena-kulig",
    image: images.marzena,
    certificates: certificatesMarzena,
    content: aboutMarzena,
    services: servicesMarzena,
  },
];
